<template>
  <div class="page-inscricao">
    <header>
      <div class="container py-5 text-white">
        <div class="row">
          <div class="col-xl-8 col-10 text-center">
            <img src="@/assets/images/lp/logo.png" alt="" class="img-fluid" />
            <h1 class="mt-4">
              Você investiria R$ 50/mês para desbloquear sua fluência do inglês
              e mudar de vida?!
            </h1>
            <h2 class="mb-5 font-open font-weight-normal">
              Esse é o único método desenvolvido por pesquisadores de Harvard
              que irá te ensinar inglês de verdade e sem enrolação!
            </h2>
            <a href="#planos" class="btn btn-primary rounded-pill px-lg-3"
              >ASSINAR AGORA</a
            >
          </div>
        </div>
      </div>
    </header>

    <section>
      <div class="container py-5 text-center">
        <div class="row justify-content-center">
          <div class="col-lg-7">
            <h1>Aprender inglês não precisa ser caro ou difícil.</h1>
            <p class="font-weight-bold">
              O Tentacle é a única escola online com metodologia comprovada de
              aceleração de inglês, com módulos flexíveis e eficazes para a sua
              idade , permitindo que os melhores alunos consigam ir do básico ao
              avançado em poucos meses.
            </p>
            <p>
              Você não precisa mais ficar 10 anos em uma escola de inglês
              aprendendo teorias que não funcionam. Aqui você aprende o inglês
              de verdade, na prática, aquele que você REALMENTE precisa e vai
              utilizar na sua vida em qualquer situação.
            </p>
          </div>
        </div>

        <div class="row justify-content-center pt-5">
          <div class="col-lg-9">
            <div class="px-4 py-5">
              <div class="row">
                <div class="col-lg-3 col-2 px-0 mt-lg-0 mt-5">
                  <img
                    src="@/assets/images/lp/polvo.png"
                    alt="Polvo"
                    class="img-fluid"
                  />
                </div>
                <div class="col-lg-9 col-10">
                  <div class="text-left pl-4">
                    <h1 class="mb-4">Para quem é o Tentacle?</h1>
                    <p class="font-weight-bold">Para quem:</p>
                    <p class="font-weight-bold">
                      - Precisa vencer o medo do inglês <br>
                      - Já testou métodos
                      tradicionais e não conseguiu se desenvolver <br>
                      - Quer
                      aprender o inglês da vida real <br>
                      - Necessidade do inglês com
                      urgência para viajar <br>
                      - Quer se destacar no mercado de
                      trabalho aprendendo o idioma que apenas 1% dos brasileiros
                      possuem fluência.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="bg-red">
      <div class="container py-5">
        <div class="row">
          <div class="col-lg-5 col-4 text-center">
            <img
              src="@/assets/images/lp/tomoto.png"
              alt="Tomoto"
              class="img-fluid"
            />

            <img
              src="@/assets/images/lp/places-mobile.png"
              alt="Places"
              class="img-fluid mt-5 d-lg-none"
            />
          </div>
          <div class="col-lg-7 col-8">
            <h1 class="mb-4">E quem é Matheus Tomoto?</h1>

            <p>
              Opa, desculpa, eu já estava esquecendo de me apresentar! Meu nome
              é Matheus Tomoto e eu já estive no seu lugar. Quando decidi tentar
              um intercâmbio, meu nível de inglês era ZERO, e eu tinha que
              prestar uma prova em apenas 3 meses.
            </p>
            <p>
              O que eu fiz? Estudei inglês nesse tempo, consegui aprender e fui
              para os EUA pela primeira vez, 8 anos atrás! Hoje, já passei por
              lugares como Harvard, MIT, Stanford e Oxford, e fui eleito um dos
              Jovens Promissores com Menos de 30 Anos pela Forbes ano passado. E
              eu só conquistei tudo isso por causa do inglês!
            </p>

            <img
              src="@/assets/images/lp/places.png"
              alt=""
              width="417"
              height="75"
              class="img-fluid d-lg-block d-none"
            />
          </div>
        </div>
      </div>
    </section> -->

    <section class="bg-red">
      <div class="container py-5 text-center">
        <h1>O QUE VOCÊ TERÁ ACESSO:</h1>

        <div class="row mt-5 justify-content-center">
          <div class="col-lg-4 pt-4">
            <div class="super-rounded bg-dark-gray h-100">
              <img
                src="@/assets/images/lp/card-1-v2.jpg"
                alt=""
                width="336"
                height="177"
                style="border-radius: 20px 20px 0 0; object-fit: cover"
                class="w-100"
              />

              <div class="p-4">
                <h2>Inglês na Prática</h2>
                <p class="text-md">- Vença a insegurança de falar inglês</p>
                <p class="text-md">
                  - Aprenda a pronúncia e vocabulário essencial para o dia a
                  dia.
                </p>
                <p class="text-md">
                  -Inglês de sobrevivência para viagens e entrevistas de
                  emprego.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 pt-4">
            <div class="super-rounded bg-dark-gray h-100">
              <img
                src="@/assets/images/lp/card-2-v2.jpg"
                alt=""
                width="336"
                height="177"
                style="border-radius: 20px 20px 0 0; object-fit: cover"
                class="w-100"
              />

              <div class="p-4">
                <h2>Gramática sem stress</h2>

                <p class="text-md">
                  - Aprenda inglês no seu próprio ritmo, com foco nas suas
                  necessidades.
                </p>
                <p class="text-md">
                  - Com exemplos práticos e usados no mundo real.
                </p>
                <p class="text-md">- Sem teorias antiquadas e obsoletas.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 pt-4">
            <div class="super-rounded bg-dark-gray h-100">
              <img
                src="@/assets/images/lp/card-3.jpg"
                alt=""
                width="336"
                height="177"
                style="border-radius: 20px 20px 0 0; object-fit: cover"
                class="w-100"
              />

              <div class="p-4">
                <h2>Inglês para Negócios e Vistos</h2>

                <p class="text-md">
                  - Destrave suas entrevistas em inglês, para empresas no Brasil
                  e no exterior
                </p>
                <p class="text-md">
                  - Aprenda o vocabulário de negócios para a sua profissão
                </p>
                <p class="text-md">
                  - Treine sua conversação com professores capacitados
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 pt-4">
            <div class="super-rounded bg-dark-gray h-100">
              <img
                src="@/assets/images/lp/card-4-v2.jpg"
                alt=""
                width="336"
                height="177"
                style="border-radius: 20px 20px 0 0; object-fit: cover"
                class="w-100"
              />

              <div class="p-4">
                <h2>Ferramentas de Aceleração de Inglês</h2>

                <p class="text-md">
                  - Você terá acesso a uma gama de ferramentas e técnicas que
                  farão seu inglês evoluir até 60 vezes mais rápido para o
                  inglês básico, intermediário e avançado.
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 pt-4">
            <div class="super-rounded bg-dark-gray h-100">
              <img
                src="@/assets/images/lp/card-5-v2.jpg"
                alt=""
                width="336"
                height="177"
                style="border-radius: 20px 20px 0 0; object-fit: cover"
                class="w-100"
              />

              <div class="p-4">
                <h2>Provas internacionais</h2>

                <p class="text-md">
                  - Você também terá acesso a preparação para provas
                  internacionais como TOEFL, IELTS, DET, CAMBRIDGE e muitas
                  outras.
                </p>
                <p class="text-md">
                  - Além de aulas de Speaking e Listening ao vivo com
                  professores altamente preparados.
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 pt-4">
            <div class="super-rounded bg-dark-gray h-100">
              <img
                src="@/assets/images/lp/card-6-v2.jpg"
                alt=""
                width="336"
                height="177"
                style="border-radius: 20px 20px 0 0; object-fit: cover"
                class="w-100"
              />

              <div class="p-4">
                <h2>Aulas de Conversação</h2>

                <p class="text-md">
                  - Toda semana você terá acesso a professores com dinâmicas
                  exclusivas para acelerar seu desenvolvimento.
                </p>
                <p class="text-md">
                  - Aulas práticas e com foco na sua necessidade.
                </p>
                <p class="text-md">
                  - Além de grupos de estudos abertos todos os dias da semana
                  disponíveis para você.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-dark-gray">
      <div class="container py-5 text-center">
        <div class="row justify-content-center">
          <div class="col-12">
            <h1 class="mb-4">Quer saber se realmente funciona?</h1>
            <p>Confira as histórias de quem já passou por aqui!</p>
          </div>
        </div>

        <VueSlickCarousel v-bind="settings">
          <div class="px-2">
            <img
              src="@/assets/images/lp/dep1.png"
              width="379"
              height="264"
              class="img-fluid"
              alt="Sample 1"
            />
          </div>
          <div class="px-2">
            <img
              src="@/assets/images/lp/dep2.png"
              width="416"
              height="288"
              class="img-fluid"
              alt="Sample 1"
            />
          </div>
          <div class="px-2">
            <img
              src="@/assets/images/lp/dep3.png"
              width="379"
              height="264"
              class="img-fluid"
              alt="Sample 1"
            />
          </div>
        </VueSlickCarousel>

        <div class="pt-5">
          <a href="#planos" class="btn btn-primary rounded-pill px-lg-3"
            >QUERO APRENDER TAMBÉM</a
          >
        </div>
      </div>
    </section>

    <section class="bg-liberty">
      <div class="container py-5">
        <div class="row py-lg-5">
          <div class="col-lg-9">
            <h1 class="mb-4">
              CONQUISTAR O INGLÊS NÃO É MAIS UMA OPÇÃO: É UMA NECESSIDADE PARA
              SOBREVIVER EM 2025
            </h1>

            <p>Se você:</p>

            <p>
              <img
                src="@/assets/images/lp/check.png"
                width="16"
                height="12"
                alt=""
              />
              Tem dificuldades para aprender inglês;
            </p>
            <p>
              <img
                src="@/assets/images/lp/check.png"
                width="16"
                height="12"
                alt=""
              />
              Nunca conseguiu encontrar um método que funcione pra você;
            </p>
            <p>
              <img
                src="@/assets/images/lp/check.png"
                width="16"
                height="12"
                alt=""
              />
              Quer desenvolver seu inglês rápido e sem enrolação;
            </p>
            <p>
              <img
                src="@/assets/images/lp/check.png"
                width="16"
                height="12"
                alt=""
              />
              Quer pagar um valor justo, que caiba no seu bolso...
            </p>

            <p class="font-weight-bold">Então o Tentacle é pra você!</p>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-red" id="planos">
      <div class="container py-5 text-center">
        <h1>
          Conquiste o seu inglês com até 50% de desconto<br
            class="d-lg-block d-none"
          />
          APENAS ESSA SEMANA
        </h1>
        <br />
        <br />
        <div class="row justify-content-center">
          <div class="col-lg-4 col-6">
            <div
              class="bg-black h-100 super-rounded text-white text-center py-lg-4 px-lg-4 py-2 px-2"
            >
              <h2>Plano Semestral</h2>
              <p class="mb-lg-1 mb-0 text-red">32% OFF</p>
              <img
                src="@/assets/images/lp/price-2.svg"
                alt=""
                class="img-fluid mb-lg-4 mb-2"
              />
              <button
                @click="dialog.semestral = true"
                class="btn btn-primary rounded-pill px-lg-5"
              >
                Quero 50% de desconto
              </button>
              <br />
              <br />
              <small><strong>*Renovado automaticamente</strong></small>
            </div>
          </div>
          <br />
          <div class="col-lg-4 col-6">
            <div
              class="bg-white h-100 super-rounded text-dark text-center py-lg-4 py-2 px-2 px-lg-4 position-relative"
            >
              <img
                src="@/assets/images/lp/best.svg"
                alt="Best"
                class="best-offer"
              />
              <h2>Plano Anual</h2>
              <p class="mb-lg-1 mb-0 text-red">50% OFF</p>
              <img
                src="@/assets/images/lp/price-1.svg"
                alt=""
                class="img-fluid mb-lg-4 mb-2"
              />
              <button
                @click="dialog.anual = true"
                class="btn btn-primary rounded-pill px-lg-5"
              >
                Quero 32% de desconto
              </button>
              <br />
              <br />
              <small> <strong>*Renovado automaticamente</strong></small>
            </div>
          </div>

          <div class="col-lg-4 col-6 mt-lg-0 mt-3">
            <div
              class="bg-black super-rounded text-white text-center py-lg-5 px-lg-4 py-2 px-2"
            >
              <h2>Plano Mensal</h2>
              <img
                src="@/assets/images/lp/price-3.svg"
                alt=""
                class="img-fluid mb-lg-4 mb-2"
              />
              <button
                @click="dialog.mensal = true"
                class="btn btn-primary rounded-pill px-lg-5"
              >
                Quero o Plano Mensal
              </button>
              <br />
              <br />
              <small> <strong>*Renovado automaticamente</strong></small>
            </div>
          </div>
        </div>

        <p class="text-center mt-5 mb-1" style="opacity: 0.6">
          Pagamento 100% seguro
        </p>
        <img src="@/assets/images/lp/bandeiras.svg" alt="" class="img-fluid" />
      </div>
    </section>

    <section>
      <div class="container py-5">
        <h1>Está com alguma dúvida?</h1>
        <p>
          Dê uma olhada nas perguntas mais <br class="d-lg-block d-none" />
          frequentes sobre o Tentacle:
        </p>

        <div class="faq-item mb-3" v-for="item in faq" :key="item.question">
          <div
            class="faq-header font-weight-bold px-3 py-4"
            @click="item.visible = !item.visible"
          >
            {{ item.question }}
          </div>
          <div
            class="faq-answer p-4"
            v-show="item.visible"
            v-html="item.answer"
          ></div>
        </div>
      </div>
    </section>

    <section class="bg-white py-5">
      <div class="container py-5 text-center">
        <div class="row justify-content-center">
          <div class="col-lg-8 d-flex flex-column align-items-center">
            <h1 class="text-dark">Alguma dúvida?</h1>
            <p class="text-gray my-4">
              Nós temos um time de consultores para te ajudar a tomar a melhor
              decisão da sua vida pelo whatsapp. Clique no botão abaixo e vamos
              realizar seu sonho internacional juntos.
            </p>

            <a
              href="https://wa.me/5511976753126?text=Ol%C3%A1!%20Gostaria%20de%20tirar%20algumas%20d%C3%BAvidas%20sobre%20o%20Tentacle..."
              class="btn btn-success d-flex align-items-center justify-content-center rounded-pill px-lg-5"
            >
              <img src="@/assets/images/lp/whatsapp.svg" alt="Whatsapp" class="mr-2" />
              Entrar em contato</a
            >
          </div>
        </div>
      </div>
    </section>

    <footer class="py-2 px-2">
      <p class="text-center mb-0">
        Copyright © 2024, Tentacle - Todos os direitos reservados.
      </p>
    </footer>

    <b-modal v-model="dialog.semestral" hide-footer hide-header centered>
      <div
        role="main"
        id="tentacle-pagina-de-vendas-semestral-5758fcd54974282a8fb6"
      ></div>
    </b-modal>

    <b-modal v-model="dialog.anual" hide-footer hide-header centered>
      <div
        role="main"
        id="tentacle-pagina-de-vendas-anual-e884296a17ef7291d94a"
      ></div>
    </b-modal>

    <b-modal v-model="dialog.mensal" hide-footer hide-header centered>
      <div
        role="main"
        id="tentacle-pagina-de-vendas-mensal-de0046a15382cda76fa8"
      ></div>
    </b-modal>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      dialog: {
        semestral: false,
        anual: false,
        mensal: false,
      },
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "350px",
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 1,
        speed: 500,
        dots: true,
        responsive: [
          {
            breakpoint: 0,
            settings: {
              slidesToShow: 1,
              centerMode: true,
              centerPadding: "10px",
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              centerMode: true,
              centerPadding: "50px",
            },
          },
        ],
      },
      faq: [
        {
          visible: false,
          question: "Qual a forma de pagamento?",
          answer:
            "O valor de investimento pode ser pago através de cartão de crédito, débito bancário, pix ou boleto à vista.",
        },
        {
          visible: false,
          question: "Quais são os termos de compra?",
          answer:
            'Atenção, ao entrar para o tentacle você concorda com os nossos Termos e Condições. <a href="https://drive.google.com/file/d/1mD-HahPp9X0129Z7t1iPdHqlSvcIf4kh/view?usp=share_link" target="_blank">Clique aqui para ler</a>.',
        },
        {
          visible: false,
          question: "Como funciona o reembolso?",
          answer:
            "Se em até 7 dias a partir da data de confirmação de pagamento você tiver assistido as aulas, seguido o método, e praticado os exercícios, e mesmo assim considerar que não teve evolução e que o curso não é para você, entre em contato conosco que devolveremos todo o seu investimento. Após o sétimo dia não serão feitos reembolsos e revogação de acesso.",
        },
        {
          visible: false,
          question: "A plataforma de pagamentos é segura?",
          answer:
            "Sim, a Hotmart é uma das maiores empresas de pagamento do mundo. Nossos alunos nunca tiveram nenhum problema de segurança ao realizarem o pagamento.",
        },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.hotmartScript();
      this.gtmScript();
    }, 1);
  },
  methods: {
    gtmScript() {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-PC82FZK");
    },
    hotmartScript() {
      let prefix = [
        "https://payment.hotmart.com",
        "https://pay.hotmart.com",
        "https://go.hotmart.com",
      ];
      function getParams() {
        let t = "",
          e = window.top.location.href,
          r = new URL(e);
        if (null != r) {
          let a = r.searchParams.get("utm_source"),
            n = r.searchParams.get("utm_medium"),
            o = r.searchParams.get("utm_campaign"),
            m = r.searchParams.get("utm_term"),
            c = r.searchParams.get("utm_content");
          -1 !== e.indexOf("?") && (t = `&sck=${a}|${n}|${o}|${m}|${c}`);
        }
        return t;
      }
      !(function () {
        var t = new URLSearchParams(window.location.search);
        t.toString() &&
          document.querySelectorAll("a").forEach(function (e) {
            for (let r = 0; r < prefix.length; r++)
              -1 !== e.href.indexOf(prefix[r]) &&
                (-1 === e.href.indexOf("?")
                  ? (e.href += "?" + t.toString() + getParams())
                  : (e.href += "&" + t.toString() + getParams()));
          });
      })();
    },
  },
  watch: {
    dialog: {
      handler() {
        setTimeout(() => {
          if (this.dialog.semestral)
            new RDStationForms(
              "tentacle-pagina-de-vendas-semestral-5758fcd54974282a8fb6",
              "UA-151728987-1"
            ).createForm();
          if (this.dialog.anual)
            new RDStationForms(
              "tentacle-pagina-de-vendas-anual-e884296a17ef7291d94a",
              "UA-151728987-1"
            ).createForm();
          if (this.dialog.mensal)
            new RDStationForms(
              "tentacle-pagina-de-vendas-mensal-de0046a15382cda76fa8",
              "UA-151728987-1"
            ).createForm();
        }, 1);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Ubuntu:wght@700&display=swap");

.page-inscricao {
  scroll-behavior: smooth;
  background: #000000;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;

  p {
    @media (max-width: 768px) {
      font-size: 10px;
    }
  }

  h1,
  h2,
  h3 {
    font-family: "Ubuntu", sans-serif;
  }

  .bg-dark-gray {
    background: #1c1c1c;
  }

  .bg-red {
    background: #ef4d57;
  }

  .font-open {
    font-family: "Open Sans", sans-serif;
  }

  .btn-primary {
    background-color: #19c7d7 !important;
    min-height: auto;
    padding: 14px 12px;
    border: none;
  }

  header {
    background-color: #ef4d57;
    background-image: url(../assets/images/lp/header.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 200px 0;
    background-position-y: center;
    background-position-x: right;

    @media (max-width: 900px) and (min-width: 500px) {
      padding: 100px 0;
    }

    @media (max-width: 500px) {
      padding: 0;

      img {
        width: 120px;
        height: 39px;
      }
    }
  }

  h1 {
    font-size: 40px;
    line-height: 46px;

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 26px;
    }
  }

  h2 {
    font-size: 24px;
    line-height: 27px;

    @media (max-width: 768px) {
      font-size: 13px;
      line-height: 15px;
    }
  }

  .text-md {
    font-size: 14px;
  }

  .super-rounded {
    border-radius: 20px;

    h2 {
      margin: 12px 0 24px;
    }
  }

  .slick-list {
    padding-top: 100px !important;
    padding-bottom: 60px !important;
  }

  .slick-slide {
    opacity: 0.5;

    &.slick-active {
      opacity: 1;
      transform: scale(1.2);
      z-index: 2;
    }
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;
    padding-inline-start: 0px !important;

    li {
      width: 30px;
      list-style-type: none;

      button {
        padding: 0;
        border: none;
        border-radius: 50%;
        font-size: 0;
        width: 6px !important;
        height: 6px !important;
        background: #515151;
      }

      &.slick-active {
        button {
          width: 9px !important;
          height: 9px !important;
          background: #9f9f9f;
        }
      }
    }
  }

  .bg-liberty {
    background: url(../assets/images/lp/bg-liberty.png);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-black {
    background: #000;
  }

  .best-offer {
    position: absolute;
    right: -45px;
    top: -55px;
    z-index: 2;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .faq-header {
    background: linear-gradient(
        96.63deg,
        rgba(255, 63, 92, 0.2) -0.26%,
        rgba(249, 48, 91, 0.2) -0.25%,
        rgba(255, 63, 92, 0.056) 99.69%
      ),
      rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    cursor: pointer;
  }

  .faq-answer {
    color: #dddddd;
    font-size: 16px;
  }

  .text-gray {
    color: #7d7d7d;
  }

  .btn-success {
    font-size: 18px;
    font-weight: 400;
  }

  .btn {
    @media (max-width: 768px) {
      font-size: 10px;
    }
  }

  #planos {
    .text-red {
      color: #D9474A;
    }
    small {
      color: #c2c2c2;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.18px;
    }
    .btn {
      @media (max-width: 768px) {
        font-size: 7px;
      }
    }
  }
}
</style>
